.check_box_specification_title {
    width: 100%;
    font-size: 28px;
    text-align: center;
    color: #666;
}

/* .prefectures_items {
    height: 365px;
    overflow: scroll;
    margin-top: 0%;
    padding-left: 2%;
} */

.prefectures_items li {
    display: flex;
    font-size: 20px;
    color: #666;
}

.prefectures_items li p {
    width: 100%;
}

.decision_button {
    margin-top: 5%;
}

.rejection_button {
    margin-top: 5%;
}
