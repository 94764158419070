.interested_item_edit_title {
    font-size: 28px;
    color: #666;
    text-align: center;
}

.selected_value {
    border: 1px solid #808080;
    border-radius: 10px;
    width: 96%;
    margin-left: 2%;
}

.selected_value ul {
    margin-left: -10%;
    list-style: none;
}

.selected_value li {
    width: 60%;
    text-align: center;
    margin-top: 5%;
    border: 1px solid #808080;
    border-radius: 30px;
    position: relative;
}

.clear_icon {
    position: absolute;
    right: 5px;
    color: #666;
}
.selected_value_title {
    font-size: 24px;
    height: 20px;
    color: #666;
    text-align: center;
}

.selection_candidate {
    width: 96%;
    margin-left: 2%;
    margin-top: 5%;
    border: 1px solid #808080;
    border-radius: 10px;
}

.selection_candidate_title {
    font-size: 24px;
    color: #666;
    text-align: center;
}

.selection_candidate_serch_box {
    width: 90%;
}

.items_of_interest {
    margin-left: -9%;
    margin-right: 5%;
}

.items_of_interest li {
    width: 100%;
    font-size: 20px;
    color: #666;
    text-align: center;
    margin-top: 5%;

    height: 50px;
    list-style: none;
    border: solid 1px #ccc;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.parent {
    font-size: 20px;
    color: #666;
}
