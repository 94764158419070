.nice_list_title {
    width: 100%;
    font-size: 28px;
    color: #666;
    text-align: center;
}

.nice_list_tab {
    display: flex;
}

.new_nice_tab {
    color: white;
    background-color: #99ccff;
}

.total_nice_tab {
    color: #99ccff;
}

.nice_list_tab div {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: solid 1px #99ccff;
    height: 30px;
    border-radius: 30px;
}

.nice_counters {
    display: flex;
}

.nice_counter {
    width: 50%;
    border: solid 1px #666;
    height: 60px;
    border-radius: 10px;
}

.nice_counter div {
    color: #666;
    text-align: center;
    margin-top: 3%;
}

.circle p {
    font-size: 16px;
    padding: 0;
}
