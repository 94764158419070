.mobile_confirm_registration_details_title {
    width: 100%;
    font-size: 28px;
    text-align: center;
    color: #666;
}

.mobile_confirm_registration_details_message p {
    font-size: 124%;
    color: #3399ff;
    text-align: left;
}
.registration_confirmation_nick_name {
    color: #666;
    font-size: 20px;
}
.registration_confirmation_nick_name_box {
    margin-left: 2px;
    margin-top: 5%;
    border: none;
    border: solid 1px;
    text-align: center;
    width: 98%;
    height: 35px;
    background-color: #f5f5f5;
    border-color: #ccc;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.registration_exercise_method {
    margin-top: 10%;
    color: #666;
    font-size: 20px;
}
.registration_exercise_method_box {
    margin-left: 2px;
    margin-top: 5%;
    border: none;
    border: solid 1px;
    text-align: center;
    width: 98%;
    height: 35px;
    background-color: #f5f5f5;
    border-color: #ccc;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.mobile_nick_name_box p {
    color: #666;
    margin-top: 5px;
}

.mobile_interesting_things {
    margin-left: 20px;
    margin-bottom: 20px;
}

.registration_self-introduction {
    margin-top: 10%;
    color: #666;
    font-size: 20px;
}

.registration_self-introduction_box {
    margin-left: 2px;
    border: none;
    border: solid 1px;
    text-align: center;
    width: 98%;
    height: 170px;
    background-color: #f5f5f5;
    border-color: #ccc;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.RegistrationConfirmation_next_button {
    margin-top: 15%;
    padding: 2% 0;
    width: 100%;
    font-size: 20px;
    color: #f5f5f5;
    background-color: #99ccff;
    border-radius: 20px;
    border-width: 0px;
    overflow: 'hidden';
}

.registration_confirmation_decision_button {
    margin-top: 10px;
}
