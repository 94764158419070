.matching_member_card_title {
    width: 100%;
    font-size: 28px;
    text-align: center;
    color: #666;
}
.matching_cards {
    overflow: scroll;
    overflow-x: hidden;
    height: 71vh;
}

.matching_cards_flex_cards {
    margin-top: 15%;
    width: 100%;
    display: flex;
}

.one_card {
    width: 50%;
    height: 240px;
    margin-left: 2%;
}
.one_card div {
    font-size: 20px;
    color: #666;
}

.matching_cards_circle p {
    text-align: center;
}

.matching_cards_circle {
    display: inline-block;
    width: 45%;
    height: 75px;
    margin-top: 0.5%;
    margin-left: 12%;
    background: #f5f5f5;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    -o-border-radius: 50px;
    -ms-border-radius: 50px;
    border-radius: 50px;
}

.matching_cards_self_introduction {
    margin-left: 2%;
    width: 90%;
    height: 110px;
    overflow: auto;
    overflow-x: hidden;
    background-color: #f5f5f5;
    border-radius: 10px;
}

.matching_cards_self_introduction {
    text-align: center;
}

.matching_cards_image {
    width: 96%;
    height: auto;
    border-radius: 5%;
}
