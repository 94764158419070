.message_list_title {
    width: 100%;
    font-size: 28px;
    text-align: center;
    color: #666;
}

.message_list {
    width: 98%;
    margin: 0 auto;
    margin-top: 8%;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 8px;
    color: #666;
    font-size: 16px;
}

.last_message_time {
    width: 40%;
    color: #666;
    font-size: 16px;
    text-align: right;
}

.message_top {
    display: flex;
    margin-top: 1vh;
}

.message_top_status {
    width: 60%;
    display: flex;

    flex-wrap: wrap;
}

.message_list_image {
    width: 100%;
    height: 25vh;
    margin-top: 1vh;
}
.circle {
    display: inline-block;
    width: 40%;
    height: 70px;
    margin-top: 0.5%;
    margin-left: 10px;
    margin-bottom: 5px;
    background: #f5f5f5;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    -o-border-radius: 50px;
    -ms-border-radius: 50px;
    border-radius: 50px;
}

.circle_online_status {
    position: absolute;
    top: 50px;
    right: 85px;
}
.circle div {
    text-align: center;
    padding: 20px 0;
}
/* .nickname_nice {
    margin-top: 10px;
    width: 40%;
} */

/* .nickname_nice div {
    width: 100%;
    font-size: 16px;
} */

.message_list_nice {
    display: flex;
    margin-left: 5%;
}

.message_list_self-introduction_box {
    margin-left: 2px;
    border: none;
    border: solid 1px;
    text-align: center;
    width: 98%;
    height: 30px;
    background-color: #fff2cc;
    border-color: #d6b656;
    border-radius: 10px;
    font-size: 16px;
}
