.age_select_title {
    width: 100%;
    font-size: 28px;
    color: #666;
    text-align: center;
}
.age_select_boxes {
    display: flex;
    width: 100%;

    margin-top: 30%;
}

.age_font {
    margin-top: 3%;
    font-size: 20px;
    color: #666;
}
