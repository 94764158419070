.other_setting_items input::placeholder {
    font-size: 20px;
}

.common_nice_member_profile {
    color: #666;
}

.common_font_size {
    font-size: 20px;
}

input::placeholder {
    font-size: 16px;
}

.nice_member_profile_titile {
    width: 100%;
    text-align: center;
    font-size: 28px;
}

.nice_profile_nickname {
    margin-top: 5%;
}

.nice_profile_nickname div {
    width: 98%;
    text-align: center;
    border: none;
    outline: none;
    border-bottom: solid 2px #ccc;
    font-size: 20px;
    color: #ccc;
}

.nice_profile_exercise_method {
    margin-top: 10%;
}
.nice_profile_exercise_method div {
    width: 98%;
    height: 30px;
    text-align: center;
    border: none;
    outline: none;
    border-bottom: solid 2px #ccc;
    font-size: 20px;
    margin-top: 5%;
    color: #ccc;
}

.nice_member_self_introduction {
    margin-top: 10%;
}

.nice_member_self_introduction div {
    border: none;
    border: solid 1px;
    outline: none;
    text-align: center;
    padding-top: 60px;
    width: 98%;
    height: 120px;
    overflow: auto;
    background-color: #f5f5f5;
    border-color: #ccc;
    border-radius: 10px;
    font-size: 20px;
}

.nice_member_interesting_detail {
    width: 100%;
    margin-left: 10%;
}

.mypage_nice_other_setting_items {
    margin-top: 5%;
}
.mypage_nice_other_setting_items div {
    margin-top: 2%;
}
.mypage_nice_other_setting_item {
    width: 95%;
    height: 30px;
    margin-top: 0;
    margin-left: 5px;
    text-align: center;
    border: none;
    outline: none;
    border-bottom: solid 2px #ccc;
    color: #ccc;
}
