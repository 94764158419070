.return_message_title {
    width: 100%;
    font-size: 28px;
    color: #666;
    text-align: center;
}

.fixed_phrase_index {
    margin-top: 5%;
    font-size: 24px;
    color: #666;
}

.fixed_phrase_select {
    width: 98%;
    height: 40px;
    font-size: 20px;
    color: #666;
    border-radius: 5px;
    border: solid 1px #666;
}

.return_message_top {
    margin-top: 15%;
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    color: #666;
}

.return_message_box {
    width: 96%;
    font-size: 20px;
    color: #666;
    display: flex;
    justify-content: center;
    align-items: center;
    border: solid 1px #666;
    border-radius: 5px;
    text-align: start;
    font-family: inherit;
}
