.common_my_profile {
    color: #666;
}

.other_setting_items input::placeholder {
    font-size: 20px;
}

.common_my_profile {
    color: #666;
}

.my_profile_common_font_size {
    font-size: 20px;
}
input::placeholder {
    font-size: 16px;
}

.my_profile_titile {
    width: 100%;
    text-align: center;
    font-size: 28px;
}

.search_member_profile_scroll {
    height: 415px;
    overflow: scroll;
}

.search_member_profile_image {
    width: 100%;
    height: auto;
}

.my_profile_nickname {
    margin-top: 5%;
}

.my_profile_nickname input {
    width: 98%;

    text-align: center;
    border: none;
    outline: none;
    border-bottom: solid 2px #ccc;
    font-size: 130%;
}

.my_profile_exercise_method {
    margin-top: 10%;
}
.my_profile_exercise_method input {
    width: 95%;
    margin-left: 5px;
    margin-top: 8%;
    text-align: center;
    border: none;
    outline: none;
    border-bottom: solid 2px #ccc;
    height: 20px;
    font-size: 130%;
}

.my_profile_self_introduction {
    margin-top: 10%;
}

.my_profile_self_introduction input {
    border: none;
    border: solid 1px;
    outline: none;
    text-align: center;
    width: 98%;
    height: 150px;
    overflow: auto;
    background-color: #f5f5f5;
    border-color: #ccc;
    border-radius: 10px;
    font-size: 130%;
}

.my_profile_interesting_detail {
    width: 100%;
    margin-left: 10%;
}

.my_profile_setting_items div {
    margin-top: 5%;
}

.my_profile_setting_items input {
    width: 95%;
    height: 30px;
    font-size: 130%;
    margin-left: 5px;
    text-align: center;
    border: none;
    outline: none;
    border-bottom: solid 2px #ccc;
}
