.InterestingThingsSelect_title {
    width: 100%;
    font-size: 24px;
    color: #666;
    text-align: center;
}

.InterestingThingsSelect_datail {
    height: 500px;
    overflow: scroll;
    width: 100%;
    font-size: 20px;
    color: #666;
    text-align: center;
    margin-left: 3%;
    height: 80vh;
    overflow: scroll;
}

.InterestingThingsSelect_datail p {
    width: 94%;
    font-size: 20px;
    color: #666;
    text-align: center;
    margin-top: 5%;
    height: 50px;
    list-style: none;
    border: solid 1px #ccc;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}
