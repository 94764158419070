.mypage_title {
    width: 100%;
    font-size: 28px;
    color: #666;
    text-align: center;
}

.my_image {
    width: 165%;
    height: auto;
}
.mypage_mystatus {
    width: 100%;
    margin-top: 3vh;
    text-align: center;
}

.mypage_value {
    width: 95%;
    height: 60px;
    background-color: #fff2cc;
    border-radius: 10px;
    border: 1px solid #d6b656;
    text-align: center;
    margin: 3% auto;
    font-size: 24px;
    color: #666;
}

.mypage_value div {
    height: 25px;
}

.mypage_tile_botton {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.mypage_tile_botton_tile_upper_row {
    width: 48%;
    height: 17vh;
    margin: 2px;
    border-radius: 10px;
    background-color: #99ccff;
}

.mypage_tile_botton_tile_lower_row {
    width: 48%;
    height: 17vh;
    margin: 2px;
    border-radius: 10px;
    background-color: #cccccc;
}

.mypage_tile_botton p {
    font-size: 20px;
    color: white;
    text-align: center;
    margin-top: 35%;
}
