.mobile_membership_registration_agreement_title {
    width: 100%;
    font-size: 28px;
    color: #666;
    text-align: center;
}

.mobile_terms_of_service_box {
    margin-left: 2%;
    width: 95%;
    height: 60vh;
    overflow: auto;
    overflow-x: hidden;
    background-color: #f5f5f5;
    border-radius: 10px;
    display: flex;
    align-items: center;
}

.mobile_terms_of_service_box p {
    width: 100%;
    font-size: 16px;
    text-align: center;
    color: #666;
}

.terms_of_service_check {
    width: 100%;
    margin-top: 7%;
    justify-content: center;
    align-items: center;
    display: flex;
    color: #666;
}
