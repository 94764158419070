.mobile_search_icon {
    width: 24%;
    flex-flow: column;
    font-size: 12px;
    color: #666;
    text-align: center;
}

.mobile_matching_list_icon {
    width: 27%;
    flex-flow: column;
    font-size: 10px;
    color: #666;
    text-align: center;
}
.mobile_message_icon {
    width: 24%;
    flex-flow: column;
    font-size: 12px;
    color: #666;
    text-align: center;
}
.mobile_maypage_icon {
    width: 24%;
    flex-flow: column;
    font-size: 12px;
    color: #666;
    text-align: center;
}

.mobile_fotter_icons {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 70px;
    margin-top: 5px;
    border: 1px solid #ccc;
    display: flex;
}

.mobile_fotter_icons div {
    border: 1px solid #ccc;
    display: flex;
}
