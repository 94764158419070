.member_list_title {
    width: 100%;
    font-size: 28px;
    color: #666;
    text-align: center;
}

.members_list {
    width: 98%;
    margin: 0 auto;
    margin-top: 4%;
    font-size: 8px;
    border: 1px solid #ccc;
    border-radius: 8px;
    color: #666;
}

.last_login {
    display: flex;
    color: #666;
    font-size: 16px;
    text-align: center;
    display: flex;
    justify-content: left;
    align-items: center;
}

.members_list_image_nickname_nice {
    width: 100%;
}

.members_list_nickname_nice {
    width: 100%;
    margin-top: 1vh;
    display: flex;
}

.members_list_nickname_nice div {
    width: 100%;
    font-size: 16px;
}

.member_list_nice_count {
    display: flex;
    margin-left: 10%;
}

.search_user_image {
    width: 100%;
    margin-top: 1vh;
    height: 25vh;
    border-radius: 5%;
}

.members_list_self-introduction_box {
    margin-left: 2px;
    border: none;
    border: solid 1px;
    text-align: center;
    width: 98%;
    height: 40px;
    background-color: #fff2cc;
    border-color: #d6b656;
    border-radius: 10px;
    font-size: 16px;
    text-align: left;
    display: flex;
    align-items: center;
    margin-top: 1vh;
}
