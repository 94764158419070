input::placeholder {
    font-size: 16px;
}
.serch_member_profile_title {
    width: 100%;
    text-align: center;
    font-size: 28px;
}
.matching_member_profile_scroll {
    height: 425px;
    overflow: scroll;
}

.serch_member_profile_image {
    width: 100%;
}

.serch_member_profile_image_cercle {
    display: inline-block;
    margin-left: 35%;
    width: 31%;
    height: 100px;
    margin-top: 0.5%;
    background: #f5f5f5;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    -o-border-radius: 50px;
    -ms-border-radius: 50px;
    border-radius: 50px;
}

.onlain_status {
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    color: #666;
}

.serch_member_profile_image_cercle p {
    width: 100%;
    text-align: center;
    margin-top: 36%;
}

.terms_of_service_check {
    display: flex;
}

.serch_member_profile_font_style {
    margin-top: 5%;
    font-size: 20px;
}

.serch_member_profile_font_style input {
    width: 98%;
    text-align: center;
    border: none;
    outline: none;
    border-bottom: solid #ccc;
    font-size: 20px;
    color: #666;
}

.serch_member_profile_font_margin {
    margin-top: 5%;
}
.serch_member_profile_font_margin input {
    height: 30px;
    font-size: 20px;
    color: #666;
}
.serch_member_self_introduction {
    margin-top: 5%;
}

.serch_member_self_introduction input {
    border: none;
    border: solid 1px;
    outline: none;
    text-align: center;
    width: 98%;
    height: 150px;
    overflow: auto;
    background-color: #f5f5f5;
    border-color: #ccc;
    border-radius: 10px;
    font-size: 20px;
}

.serch_member_interesting_detail {
    width: 100%;
    margin-left: 10%;
}

.serch_member_self_age {
    margin-top: 5%;
}

.serch_member_self_age input {
    width: 98%;
    text-align: center;
    border: none;
    outline: none;
    border-bottom: solid #ccc;
}

.serch_member_self_gender {
    margin-top: 5%;
}

.serch_member_self_gender input {
    width: 98%;
    text-align: center;
    border: none;
    outline: none;
    border-bottom: solid #ccc;
}

.serch_member_self_residence {
    margin-top: 5%;
}

.serch_member_self_residence input {
    width: 98%;
    text-align: center;
    border: none;
    outline: none;
    border-bottom: solid #ccc;
}
