.chat_screen {
    max-height: 72vh;
    overflow: scroll;
    margin-top: 0;
}
.message_list_footer {
    width: 100%;
    position: absolute;
    margin-bottom: 0vh;
}

.message_list_input_box {
    width: 64%;
    height: 30px;
    border: solid 1px #666;
    border-radius: 20px;
}
.send_message {
    max-width: 70%;
    margin-top: 6%;
    margin-right: 10px;
    display: inline-block;
    padding: 10px;
    overflow-wrap: break-word;
    border-radius: 12px;
    background: #ccffcc;
}
.send_configuration {
    display: flex;
    justify-content: flex-end;
}
