.service_registration_containt {
    overflow: scroll;
    height: 70vh;
}

.service_registration_box input::placeholder {
    font-size: 20px;
    color: #ccc;
}

input::placeholder {
    font-size: 20px;
    color: #ccc;
}

.service_registration_title {
    font-size: 28px;
    display: flex;
    justify-content: center;
    color: #666;
}

.service_registration_box div {
    font-size: 20px;
    color: #666;
}

.service_registration_box input {
    width: 98%;
    margin-top: 5%;
    text-align: center;
    font-size: 20px;
    border: none;
    outline: none;
    border-bottom: solid #ccc;
}

.service_registration {
    margin-top: 10px;
    font-size: 20px;
    color: #666;
    display: flex;
    justify-content: space-between;
}

.input_box_service_registration {
    border: none;
    border: solid 1px;
    outline: none;
    text-align: center;
    width: 98%;
    height: 15vh;
    overflow: auto;
    background-color: #f5f5f5;
    border-color: #ccc;
    border-radius: 10px;
}

.word_counter {
    margin-top: 25px;
    font-size: 16px;
}

.interesting_things {
    color: #666;
}
