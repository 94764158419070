.matching_list_matching_lists {
    overflow: scroll;
    overflow-x: hidden;
    height: 71vh;
}

.matching_member_list_title {
    width: 100%;
    font-size: 28px;
    text-align: center;
    color: #666;
}

.matching_lists_top div {
    font-size: 20px;
    color: #666;
}

.matching_members_list {
    width: 98%;
    margin: 0 auto;
    margin-top: 4.1%;
    font-size: 8px;
    border: 1px solid #ccc;
    border-radius: 8px;
    display: flex;
}

.matching_members_list_circle {
    display: inline-block;
    width: 16%;
    height: 50px;
    margin-top: 0.5%;
    background: #f5f5f5;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    -o-border-radius: 50px;
    -ms-border-radius: 50px;
    border-radius: 50px;
}

.image_area p {
    font-size: 14px;
}
.matching_list_nick_name_last_login {
    width: 100%;
    display: flex;
}

.mobile_members_list_self-introduction_box {
    margin-left: 2px;
    border: none;
    border: solid 1px;
    text-align: center;
    width: 100%;
    height: 30px;
    background-color: #fff2cc;
    border-color: #d6b656;
    border-radius: 10px;
}

.mobile_search_icon {
    width: 24%;
    flex-flow: column;
    font-size: 12px;
    color: #666;
    text-align: center;
}

.matching_list_matching_list_icon {
    width: 27%;
    flex-flow: column;
    font-size: 10px;
    color: #666;
    text-align: center;
}
.matching_list_message_icon {
    width: 24%;
    flex-flow: column;
    font-size: 12px;
    color: #666;
    text-align: center;
}
.matching_list_maypage_icon {
    width: 24%;
    flex-flow: column;
    font-size: 12px;
    color: #666;
    text-align: center;
}

.matching_list_fotter_icons {
    margin-top: 22px;
    border: 1px solid #ccc;
    display: flex;
}

.matching_list_fotter_icons div {
    border: 1px solid #ccc;
    display: flex;
}
