/* 画面によりスクロールスクリーン高さ変更 */
.content_common {
    overflow: scroll;
    overflow-x: hidden;
    height: 57vh;
}

.up_message_screen {
    overflow: scroll;
    overflow-x: hidden;
    height: 50vh;
}

.main_content_common {
    overflow: scroll;
    overflow-x: hidden;
    height: 62vh;
}
