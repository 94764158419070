.exerciseMethodSelect_title {
    width: 100%;
    font-size: 28px;
    color: #666;
    text-align: center;
}

.exerciseMethodSelect_datail {
    width: 100%;
    font-size: 20px;
    color: #666;
    text-align: center;
    margin-left: 1.5%;
    height: 420px;
    overflow: scroll;
}

.exerciseMethodSelect_datail p {
    width: 96%;
    border: solid 1px #ccc;
    border-radius: 10px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}
