.condition_specification_title {
    width: 100%;
    font-size: 28px;
    text-align: center;
    color: #666;
}

.condition_specification_setting {
    color: #666;
    font-size: 20px;
    overflow: scroll;
    overflow-x: hidden;
    height: 65vh;
}

.condition_specification_setting p {
    width: 100%;
    display: flex;
}
.condition_specification_setting p div {
    text-align: center;
    width: 34%;
}
