.footer_decision_botton_layoout {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100px;
}

.footer_decision_botton_layoout Button {
    width: 100%;
    height: 42px;
    padding: 2%;
    border-radius: 50px;
    color: white;
    font-size: 150%;
    margin-top: 30px;
    background-color: #99ccff;
    padding: 0;
}
